var $ = jQuery.noConflict();

//chiamata ajax di tipo POST per invio dati del form a mailer.php
$('form').on('submit', function (e) {
  e.preventDefault();
  var request = $.ajax({
    type: 'post',
    url: 'mailer.php',
    data: $('form').serialize(),
    dataType:'JSON'
  });
  
  request.done(function(data){
    if(data.response === 0) {
      window.location.href = './grazie';
    }
  });
});

//regole della validazione del form con validate.js
$(function(){
  $.validator.addMethod("phoneL", function(value, element) {
    return this.optional(element) || /^[0-9+]+$/.test(value);
  }, "Inserisci un numero di telefono senza spazi");
  
  $('form').validate({
    highlight: function (element, errorClass, validClass) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
          $("#select2-" + elem.attr("id") + "-container").parent().addClass(errorClass); 
      } else {
          elem.addClass(errorClass);
      }
    },    
    unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
             $("#select2-" + elem.attr("id") + "-container").parent().removeClass(errorClass);
        } else {
            elem.removeClass(errorClass);
        }
    },
    errorPlacement: function(error, element) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
          element = $(".errorTxt.frmSelect"); 
          error.insertAfter(element);
      } else if(elem.attr('id') === "frmPrivacy") {
          element = $(".errorTxt.frmPrivacy"); 
          error.insertAfter(element);
      } else {
          error.insertAfter(element);
      }
    },
    rules: {
      frmNome: {
        required: true,
        minlength: 4     
      },
      frmCognome: {
        required: true,
        minlength: 4     
      },
      frmAzienda: {
        required: true,
        minlength: 4     
      },
      frmEmail: {
        required: true,
        email: true
      },
      frmTel: {
        required: true,
        phoneL: true
      },
      frmSelect: { 
        required: true
      },
      frmPrivacy: { 
        required: true
      }
    },
    messages: {
      frmNome: {
        required: "Questo campo è obbligatorio",
        minlength: "Il campo deve essere di minimo 4 caratteri"
      },
      frmCognome: {
        required: "Questo campo è obbligatorio",
        minlength: "Il campo deve essere di minimo 4 caratteri"
      },
      frmAzienda: {
        required: "Questo campo è obbligatorio",
        minlength: "Il campo deve essere di minimo 4 caratteri"
      },
      frmEmail: {
        required: "Questo campo è obbligatorio",
        email: "Per favore inserisci una mail valida"
      },
      frmTel: {
        required: "Questo campo è obbligatorio",
        digits: "Per favore inserisci il tuo numero"
      },
      frmPrivacy: { required: "Questo campo è obbligatorio" }
    }
  });
});

//lancio grecaptcha.execute() all'azione submit
grecaptcha.ready(function() {
  grecaptcha.execute('6LdjXawnAAAAAJE-rnmFTDA31pvQujXERIlbl4sK', {action: 'submit'}).then(function(token) {
    var response = document.querySelector('#token-response');
    response.value = token;
  });
});